import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';
import { closePopup } from '../actions/popup';

class ErrorPopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { index, message = 'Caught Error...', onClosePopup } = this.props;
    return (
      <div className="reveal small" style={{ display: 'block', height: 'initial', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <a onClick={onClosePopup} style={{ float: 'right' }}>×</a>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="small-1 columns">
            <img src="/images/jq.toast.error.png" />
          </div>
          <div className="small-11 columns end">
            {message}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedErrorPopup = connect(null, mapDispatchToProps)(ErrorPopup);
export default ConnectedErrorPopup;
